import { PageProps, graphql } from 'gatsby'
import { FC, useEffect, useState } from 'react'
import { Typography, Carousel } from 'antd'
import Img from 'gatsby-image'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import MenuList from '@components/menu/List'
import StyledContainer from '@root/ic-ui-react/StyledContainer'
import YelpReview from '@root/components/YelpReviews'

export const pageQuery = graphql`
  query {
    slide1: file(relativePath: { eq: "slider-images/slide1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 4542, maxHeight: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide2: file(relativePath: { eq: "slider-images/slide2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4542, maxHeight: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage: FC<PageProps> = (props) => {
  const [slidesPerView, setSlidesPerView] = useState(1.1)

  useEffect(() => {
    if (window) {
      if (window.screen.availWidth > 768) {
        setSlidesPerView(2.2)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Carousel dotPosition="bottom">
        <Img fluid={props.data.slide1.childImageSharp.fluid} />
        <Img fluid={props.data.slide2.childImageSharp.fluid} />
      </Carousel>

      <MenuList />

      <StyledContainer fluid relax>
        <Typography.Title>What People Think About Us</Typography.Title>
        <YelpReview slidesPerView={slidesPerView} />
      </StyledContainer>
    </Layout>
  )
}
export default IndexPage
