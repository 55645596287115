import { useState, useEffect, useCallback } from 'react'

import { fetchYelpReviews } from '@services/ic.service'
import YelpReview from '@models/yelp-review'

interface Result {
  loading: boolean
  yelpReviews: YelpReview[] | undefined
  error: Error | undefined
  reload: () => void
}

export default (): Result => {
  const [yelpReviews, setYulpReviews] = useState<YelpReview[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  const loadData = useCallback(() => {
    setLoading(true)
    fetchYelpReviews()
      .then((response) => {
        setYulpReviews(response.data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  return { loading, yelpReviews, error, reload: loadData }
}
