import axios, { AxiosResponse, AxiosPromise } from 'axios'

import Contact from '@root/models/contact'
import YelpReview from '@root/models/yelp-review'
import InstagramFeed from '@root/models/instagram-feed'

export const client = axios.create({
  baseURL: process.env.GATSBY_API_URL || 'https://pita-pitakilb-backend.herokuapp.com/ic-api',
})

export const submitContactForm = (payload: Contact): Promise<AxiosResponse> =>
  client.post('/contact', payload)

export const fetchInstagramFeed = (): AxiosPromise<InstagramFeed[]> => client.get('/instagram')

export const fetchYelpReviews = (): AxiosPromise<YelpReview[]> => client.get(`/yelp/reviews`)

export const fetchBusinessInfo = (): AxiosPromise<unknown> => client.get(`/yelp`)
